@font-face {
    font-family: 'Oswald';
    src: url('../fonts/Oswald-VariableFont_wght.ttf') format('truetype'); /* Adjust this path */
    font-weight: normal; /* Normal weight */
    font-style: normal; /* Normal style */
}
* {
    font-family: 'Oswald', sans-serif; /* Use the custom font */
}

.landing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    width: 100%;
    padding: 0px;
    margin-bottom: 5px;
}

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0px;
}

.landing-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.landing-logo {
    height: auto; 
    margin: 3rem; 
    border-radius: 25px;
}
.landing-title {
    text-align: center;
}

.landing-button{
    margin-left: 5px;
}

.landing-mini-logo {
    width: 25px; 
    height: auto; 
}

.datatable-container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
}

.add-item-button {
    align-self: flex-start;
    margin-top: 5px; 
    margin-bottom: 40px;
    height: 25px;
}

@media (max-width: 500px) {
    .landing-logo {
        width: 100%;
    }
}

@media (min-width: 501px) {
    .landing-logo {
        width: 500px;
    }
}